import fetchCurrency from '@lyra/core/api/fetchCurrency'
import { useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { Address } from 'viem'

import { DepositNetwork } from '../constants/chains'
import { CollateralId, DepositTokenId } from '../constants/tokens'
import {
  fetchEstimateNativeBridgeGasFee,
  fetchLayerZeroDepositQuote,
  fetchSocketBridgeFee,
  getDepositContractAddresses,
} from '../utils/bridge'
import { getCollateralForDepositToken } from '../utils/tokens'
import useAuth from './useAuth'

export type DepositQuote = {
  fee: bigint
  feeToken: DepositTokenId
  availableDepositCap: number
}

const REFRESH_INTERVAL_MS = 30_000

// Available deposit cap of corresponding token/collateral on Lyra Chain
const fetchAvailableDepositCap = async (depositToken: DepositTokenId) => {
  const collateralId = getCollateralForDepositToken(depositToken)
  // USDC has no deposit cap
  if (collateralId === CollateralId.USDC) {
    return Number.MAX_SAFE_INTEGER
  }
  // totalCap in 10^18, totalSupply in token decimal
  const currency = await fetchCurrency({ currency: collateralId })

  const totalSupply = currency.result.asset_cap_and_supply_per_manager.srm_total_supply
    ? +currency.result.asset_cap_and_supply_per_manager.srm_total_supply
    : 0
  // Adjust to token decimals
  const tokenTotalCap = currency.result.asset_cap_and_supply_per_manager.srm_asset_cap
    ? +currency.result.asset_cap_and_supply_per_manager.srm_asset_cap
    : Number.MAX_SAFE_INTEGER

  return tokenTotalCap - totalSupply
}

const fetchLayerZeroQuote = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint
): Promise<DepositQuote> => {
  const quote = await fetchLayerZeroDepositQuote(ownerAddress, network, token, amount)
  return {
    fee: quote.fee,
    feeToken: DepositTokenId.ETH,
    availableDepositCap: Number.MAX_SAFE_INTEGER,
  }
}

const fetchNetworkQuote = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint
): Promise<DepositQuote> => {
  if (getDepositContractAddresses(network, token).type === 'layer-zero') {
    return await fetchLayerZeroQuote(ownerAddress, network, token, amount)
  }
  const [estGasFee, socketFee, availableDepositCap] = await Promise.all([
    fetchEstimateNativeBridgeGasFee(ownerAddress, network),
    fetchSocketBridgeFee(network, token),
    fetchAvailableDepositCap(token),
  ])

  const fee = socketFee + estGasFee
  return {
    fee,
    feeToken: DepositTokenId.ETH,
    availableDepositCap,
  }
}

export default function useDepositQuote(
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint
): {
  data: DepositQuote | undefined
  error: Error
  isLoading: boolean
} {
  const isLayerZeroBridge = getDepositContractAddresses(network, token).type === 'layer-zero'
  const { user } = useAuth()
  const ownerAddress = user?.ownerAddress
  const { data, isLoading, error } = useSWR(
    ownerAddress
      ? ['DepositQuote', ownerAddress, network, token, isLayerZeroBridge ? BigInt(0) : amount]
      : null,
    ([_, ownerAddress, network, token, amount]) =>
      fetchNetworkQuote(ownerAddress, network, token, amount),
    {
      refreshInterval: REFRESH_INTERVAL_MS,
    }
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return useMemo(
    () => ({
      data,
      isLoading,
      error,
    }),
    [data, isLoading, error]
  )
}
